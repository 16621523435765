import ReactGA from 'react-ga4';

export const trackEvent = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

export const trackButtonClick = (buttonName) => {
  trackEvent('Button Click', buttonName, 'User Interaction');
};

export const trackFormSubmission = (formName, value = null) => {
  trackEvent('Form Submission', formName, 'User Action');
  
  // Track form submissions as conversions
  const eventParams = {
    category: 'Conversion',
    action: 'Form Submit',
    label: formName,
  };
  
  if (value) {
    eventParams.value = value;
  }
  
  ReactGA.event(eventParams);
};

export const trackPageView = (pageName) => {
  trackEvent('Page View', pageName, 'Navigation');
};

// Track specific form submissions
export const trackGuideFormSubmit = () => {
  trackFormSubmission('Free Guide Form');
};

export const trackContactFormSubmit = () => {
  trackFormSubmission('Contact Form');
};

// Track navigation to contact page
export const trackContactPageView = () => {
  trackPageView('Contact Page');
};

export const trackVideoInteraction = (action, videoName) => {
  trackEvent('Video', action, videoName);
};

export const trackPackageSelection = (packageType, value) => {
  // Track the package selection as an event
  trackEvent('Package Selection', packageType, 'Publishing Services');
  
  // Track as a conversion with value
  ReactGA.event({
    category: 'Conversion',
    action: 'Package Selection',
    label: packageType,
    value: value
  });
};

export const trackPublishingPageView = () => {
  trackPageView('Publishing Services');
}; 