import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Player from '@vimeo/player';
import '../styles/About.css';

const About = () => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/');
    setTimeout(() => {
      const guideSection = document.getElementById('free-guide-section');
      if (guideSection) {
        guideSection.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  useEffect(() => {
    // Load Vimeo Player API script
    const script = document.createElement('script');
    script.src = "https://player.vimeo.com/api/player.js";
    script.async = true;
    
    script.onload = () => {
      setIsVideoLoaded(true);
      // Initialize Vimeo player after script loads
      const iframe = document.querySelector('#story-video');
      const player = new Player(iframe);

      // Play and unmute when video section is in view
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            player.play();
            player.setVolume(1);
          }
        });
      }, { threshold: 0.5 });

      // Observe the video section
      const videoSection = document.querySelector('.video-section');
      if (videoSection) {
        observer.observe(videoSection);
      }

      // Stop video when it ends and show first frame
      player.on('ended', () => {
        player.pause();
        player.setCurrentTime(0);
      });

      return () => {
        observer.disconnect();
        player.off('ended');
      };
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="about-page">
      <section className="about-hero">
        <div className="hero-content">
          <h1>About The Executive Press</h1>
          <p>Empowering authors to share their stories with the world</p>
        </div>
      </section>

      <section className="mission-section">
        <div className="container">
          <h2>Our Mission</h2>
          <p>At The Executive Press, we believe every author deserves the opportunity to share their story with the world. Our mission is to provide professional publishing services that transform manuscripts into beautifully crafted books while maintaining the highest standards of quality and integrity.</p>
        </div>
      </section>

      <section className="video-section">
        <div className="container">
          <h2>Our Story</h2>
          <p>Learn about our journey and commitment to authors</p>
          <div className="video-wrapper">
            <div className="vimeo-container">
              <iframe 
                id="story-video"
                src="https://player.vimeo.com/video/1047191267?h=20d03fd5d9&autoplay=1&controls=1&title=0&byline=0&portrait=0&sidedock=0"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                className={isVideoLoaded ? 'loaded' : ''}
                title="Executive Press Introduction"
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      <section className="values-section">
        <div className="container">
          <h2>Our Values</h2>
          <div className="values-grid">
            <div className="value-card">
              <h3>Excellence</h3>
              <p>We maintain the highest standards in editing, design, and production to ensure your book stands out.</p>
            </div>
            <div className="value-card">
              <h3>Integrity</h3>
              <p>We believe in transparent communication and honest partnerships with our authors.</p>
            </div>
            <div className="value-card">
              <h3>Innovation</h3>
              <p>We stay ahead of industry trends to provide cutting-edge publishing solutions.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="services-section">
        <div className="container">
          <h2>Our Services</h2>
          <div className="services-grid">
            <div className="service-item">
              <img src={process.env.PUBLIC_URL + "/Images/publishing-services.png"} alt="Publishing Services" />
              <div className="service-content">
                <h3>Publishing Services</h3>
                <p>We have established a realm of opportunities for authors dedicated to publishing their work. We offer assistance, built upon the foundation of traditional publishing principles, emphasizing top-notch services and editorial excellence.</p>
              </div>
            </div>

            <div className="service-item">
              <img src={process.env.PUBLIC_URL + "/Images/editing.png"} alt="Editing and Proofreading" />
              <div className="service-content">
                <h3>Editing and Proofreading</h3>
                <p>Our team of experienced editors provides comprehensive editing and proofreading services to ensure the highest quality for your manuscript.</p>
              </div>
            </div>

            <div className="service-item">
              <img src={process.env.PUBLIC_URL + "/Images/marketing.png"} alt="Marketing Services" />
              <div className="service-content">
                <h3>Marketing Services</h3>
                <p>We offer tailored promotional strategies to maximize book visibility through targeted outreach to your ideal readership. Our expert advice on leveraging digital platforms ensures enhanced exposure, complemented by creative campaigns designed to amplify your book's impact.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="partners-section">
        <div className="container">
          <h2>Our Partners</h2>
          <div className="partners-grid">
            <img src={process.env.PUBLIC_URL + "/Images/Ingram.jpg"} alt="Ingram" />
            <img src={process.env.PUBLIC_URL + "/Images/Waterstones.jpg"} alt="Waterstones" />
            <img src={process.env.PUBLIC_URL + "/Images/Amazon.png"} alt="Amazon" />
            <img src={process.env.PUBLIC_URL + "/Images/barnes-noble.jpg"} alt="Barnes & Noble" />
            <img src={process.env.PUBLIC_URL + "/Images/twsj.jpg"} alt="The Wall Street Journal" />
          </div>
        </div>
      </section>

      <section className="contact-section">
        <div className="container">
          <h2 style={{ color: 'white' }}>Start Your Publishing Journey</h2>
          <p style={{ color: 'white', opacity: '1' }}>Ready to bring your book to life? Contact us today to discuss your publishing goals.</p>
          <button className="contact-button" onClick={handleGetStarted}>Get Started</button>
        </div>
      </section>
    </div>
  );
};

export default About; 