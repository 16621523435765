import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import PublishingServices from './pages/PublishingServices';
import MarketingServices from './pages/MarketingServices';
import About from './pages/About';
import Contact from './pages/Contact';
import './styles/App.css';

// Initialize GA4
ReactGA.initialize('G-TJ79QMWZDH');

// Create a ScrollToTop component that also tracks page views
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: pathname });
  }, [pathname]);

  return null;
}

function App() {
  useEffect(() => {
    // Add Fastbots script
    const script = document.createElement('script');
    script.src = 'https://app.fastbots.ai/embed.js';
    script.defer = true;
    script.setAttribute('data-bot-id', 'cm5wl1jmu05lrs5bwaas7g29t');
    
    document.body.appendChild(script);

    return () => {
      // Only remove the script if it exists in the document
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <div className="app">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/publishing-services" element={<PublishingServices />} />
          <Route path="/marketing-services" element={<MarketingServices />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App; 