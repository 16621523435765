import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import '../styles/Navbar.css';
import { trackButtonClick } from '../utils/analytics';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Check if we're on the home page
  const isHomePage = location.pathname === '/';

  useEffect(() => {
    const handleScroll = () => {
      // Only apply scroll logic on home page
      if (isHomePage) {
        const videoHeight = window.innerHeight - 80; // Subtract navbar height
        setIsScrolled(window.scrollY > videoHeight);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isHomePage]);

  // Reset scroll state when changing pages
  useEffect(() => {
    setIsScrolled(false);
  }, [location.pathname]);

  const handleGetStarted = () => {
    trackButtonClick('Get Started - Navbar');
    navigate('/');
    setTimeout(() => {
      const guideSection = document.getElementById('free-guide-section');
      if (guideSection) {
        guideSection.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  // Determine navbar class based on page and scroll position
  const navbarClass = `navbar ${isHomePage && !isScrolled ? 'transparent' : 'solid'}`;

  return (
    <nav className={navbarClass}>
      <div className="navbar-container">
        <Link to="/" className="navbar-brand">
          <img 
            src={process.env.PUBLIC_URL + "/Images/logo.png"} 
            alt="Executive Press Logo" 
            className="navbar-logo-img"
          />
          <span className="navbar-logo-text">Executive Press</span>
        </Link>
        
        <div className={`navbar-menu ${isOpen ? 'active' : ''}`}>
         
          <div 
            className="nav-item-dropdown"
            onMouseEnter={() => setIsServicesOpen(true)}
            onMouseLeave={() => setIsServicesOpen(false)}
          >
            <span className="nav-item">
              Services
              <span className="dropdown-arrow">▼</span>
            </span>
            <div className={`dropdown-menu ${isServicesOpen ? 'show' : ''}`}>
              <Link to="/publishing-services" className="dropdown-item">Publishing Services</Link>
              <Link to="/marketing-services" className="dropdown-item">Marketing Services</Link>
            </div>
          </div>
          <Link to="/about" className="nav-item">About</Link>
          <Link to="/contact" className="nav-item">Contact</Link>
          <button className="nav-button" onClick={handleGetStarted}>Get Started</button>
        </div>

        <div className="hamburger" onClick={() => setIsOpen(!isOpen)}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
