import React, { useState, useEffect, useRef } from 'react';
import '../styles/Home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faCheck, faStar, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import VideoHeader from '../components/VideoHeader';
import { trackFormSubmission, trackButtonClick } from '../utils/analytics';
import Player from '@vimeo/player';

const Home = () => {
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [shuffledBooks, setShuffledBooks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [guideFormData, setGuideFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    service: 'publishing',
    message: ''
  });
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showAuthorStory, setShowAuthorStory] = useState(false);
  const [selectedAuthor, setSelectedAuthor] = useState(null);
  const [isTrailerLoaded, setIsTrailerLoaded] = useState(false);

  // Function to shuffle array
  const shuffleArray = (array) => {
    let shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const books = [
    { image: '/Books/book1.jpg', title: 'Fritz', author: 'Robert Livingston' },
    { image: '/Books/book2.jpg', title: 'Full Circle - Book 3', author: 'J.D. Williams' },
    { image: '/Books/book3.jpg', title: 'Islam and United States', author: 'Glen R. Cook' },
    { image: '/Books/book4.jpg', title: 'Shelter from the Storms', author: 'Linda Caggiano' },
    { image: '/Books/book5.jpg', title: 'The Gift of God\'s Word', author: 'Pamella Christensen' },
    { image: '/Books/book6.jpg', title: 'Glimpses.., Now I Can See', author: 'Elizabeth A. Roberts' }
  ];

  const testimonials = [
    {
      author: "Robert Livingston",
      book: "Fritz",
      image: process.env.PUBLIC_URL + "/Authors/robert-livingston.jpg",
      quote: "The editing actually was the most profound part for me. I was challenged to rethink how I had formatted some of the content in my book.",
      buttonText: "Read His Publishing Story"
    },
    {
      author: "Elizabeth Roberts",
      book: "Glimpses.., Now I Can See",
      image: process.env.PUBLIC_URL + "/Authors/elizabeth-roberts.jpg",
      quote: "There's so much to know about the publishing industry, and when you're somebody coming in like me, that knows nothing about marketing, about promotion, about editing...that's what was so wonderful about Executive-Press.",
      buttonText: "Read Her Publishing Story"
    }
  ];

  // Update the videoData object
  const videoData = {
    video: process.env.PUBLIC_URL + "/Book Trailer/Dan Arthur Busby.mp4",
    title: "Dan Arthur Busby",
    author: "Dan Arthur Busby",
    description: "Experience the compelling journey of Dan Arthur Busby as he shares his remarkable story of resilience and determination. Through personal challenges and triumphs, this narrative captures the essence of human perseverance and the power of unwavering spirit."
  };

  useEffect(() => {
    setShuffledBooks(shuffleArray(books));
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide(prevSlide => {
        return prevSlide === 1 ? 0 : 1;
      });
    }, 3000);

    return () => clearInterval(timer);
  }, []);

  const nextSlide = () => {
    setCurrentSlide(currentSlide === 1 ? 0 : 1);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? 1 : 0);
  };

  // Group books into pairs
  const bookPairs = [];
  for (let i = 0; i < shuffledBooks.length; i += 2) {
    bookPairs.push(shuffledBooks.slice(i, i + 2));
  }

  // Update the books array grouping logic
  const bookTriples = [];
  for (let i = 0; i < shuffledBooks.length; i += 3) {
    if (i + 2 < shuffledBooks.length) {
      bookTriples.push(shuffledBooks.slice(i, i + 3));
    }
  }

  // Update the scrollToGuide function
  const scrollToGuide = () => {
    trackButtonClick('Get Started - Home');
    const guideSection = document.getElementById('free-guide-section');
    if (guideSection) {
      guideSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleReadOurStory = () => {
    navigate('/about');
  };

  const handleGuideInputChange = (e) => {
    setGuideFormData({
      ...guideFormData,
      [e.target.name]: e.target.value
    });
  };

  const handleGuideSubmit = async (e) => {
    e.preventDefault();
    
    try {
      setIsLoading(true);

      const templateParams = {
        to_name: 'Liam Henry',
        from_name: guideFormData.firstName,
        from_email: guideFormData.email,
        from_phone: guideFormData.phone,
        service_type: guideFormData.service,
        message: `
New Guide Request:
------------------
Full Name: ${guideFormData.firstName}
Email: ${guideFormData.email}
Phone Number: ${guideFormData.phone}
Service Inquiry: ${guideFormData.service}
Message: ${guideFormData.message || 'No message provided'}
        `,
      };

      emailjs.init("KqRJLAWe3g_UkcB2p");

      const response = await emailjs.send(
        'service_853ldth',
        'template_fsco3b9',
        templateParams
      );

      if (response.status === 200) {
        setIsLoading(false);
        setShowSuccessDialog(true);
        setGuideFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          service: 'publishing',
          message: ''
        });
        trackFormSubmission('Free Guide Form');
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error:', error);
      alert('There was an error sending your request. Please try again or contact us directly.');
    }
  };

  const handleCloseSuccessDialog = () => {
    setShowSuccessDialog(false);
  };

  const handleAuthorStoryClick = (author) => {
    setSelectedAuthor(author);
    setShowAuthorStory(true);
  };

  const handleCloseAuthorStory = () => {
    setShowAuthorStory(false);
    setSelectedAuthor(null);
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://player.vimeo.com/api/player.js";
    script.async = true;
    
    script.onload = () => {
      setIsTrailerLoaded(true);
    };

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="home">
      <VideoHeader />
      <section className="hero">
        <div className="hero-content">
          <h1 style={{ color: '#333' }}>
            <span className="highlight">Your</span><br />
            Publishing<br />
            World
          </h1>
          <p style={{ color: '#333', fontWeight: '500' }}>Your guide to becoming a published author. We empower you to transform your manuscript into a professionally published book available to readers around the world.</p>
          <button className="free-guide-button" onClick={scrollToGuide}>Get Started</button>
        </div>
        <div className="hero-books">
          <div className="books-showcase">
            <div className="book-column left">
              <img src="/Books/book1.jpg" alt="Fritz" className="book-cover" />
              <img src="/Books/book4.jpg" alt="Shelter from the Storms" className="book-cover" />
            </div>
            <div className="book-column center">
              <img src="/Books/book2.jpg" alt="Full Circle - Book 3" className="book-cover" />
              <img src="/Books/book5.jpg" alt="The Gift of God's Word" className="book-cover" />
            </div>
            <div className="book-column right">
              <img src="/Books/book3.jpg" alt="Islam and United States" className="book-cover" />
              <img src="/Books/book6.jpg" alt="Glimpses.., Now I Can See" className="book-cover" />
            </div>
          </div>
        </div>
      </section>

      {/* Book Showcase Section */}
      <section className="book-showcase">
        <div className="section-header">
          <h2>Featured Books</h2>
          <p>Discover our latest published works</p>
        </div>
        
        <div className="carousel-container">
          <button className="carousel-arrow prev" onClick={prevSlide}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <div className="carousel">
            {bookTriples.map((triple, index) => (
              <div 
                key={index} 
                className={`carousel-slide ${index === currentSlide ? 'active' : ''}`}
                style={{
                  transform: `translateX(${100 * (index - currentSlide)}%)`
                }}
              >
                <div className="book-triple">
                  {triple.map((book, bookIndex) => (
                    <div key={bookIndex} className="featured-book-card">
                      <div className="book-image-container">
                        <img src={book.image} alt={book.title} className="featured-book-image" />
                      </div>
                      <div className="featured-book-info">
                        <h3>{book.title}</h3>
                        <p>{book.author}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <button className="carousel-arrow next" onClick={nextSlide}>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>

        <div className="carousel-dots">
          {bookTriples.map((_, index) => (
            <span 
              key={index} 
              className={`dot ${index === currentSlide ? 'active' : ''}`}
              onClick={() => setCurrentSlide(index)}
            />
          ))}
        </div>
      </section>

      {/* Book Trailer Section */}
      <section className="book-trailer">
        <div className="trailer-container">
          <div className="trailer-content-wrapper">
            <div className="trailer-video">
              <div className="vimeo-container">
                <iframe 
                  id="trailer-video"
                  src="https://player.vimeo.com/video/1047190828?h=08e9dbd18c&controls=1&title=0&byline=0&portrait=0&sidedock=0"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  className={isTrailerLoaded ? 'loaded' : ''}
                  title="Dan Arthur Busby"
                ></iframe>
              </div>
            </div>
            <div className="trailer-content">
              <h2>{videoData.title}</h2>
              <p>{videoData.description}</p>
              <p className="author">By {videoData.author}</p>
            </div>
          </div>
        </div>
      </section>

      {/* Updated Authors Section */}
      <section className="authors">
        <div className="authors-header">
          <h2>Our Authors</h2>
          <p>Executive Press has helped thousands of ambitious authors publish books and fulfill their goals. Read their stories and learn how they made the leap from amateur writer to professionally published author.</p>
          <button className="meet-authors-btn">Meet Our Authors</button>
        </div>
        <div className="authors-image-cluster">
          <div className="floating-images">
            <img 
              src={process.env.PUBLIC_URL + "/Authors/robert-livingston.jpg"} 
              alt="Robert Livingston" 
              className="author-image large"
              style={{ top: '5%', right: '25%' }}
            />
            <img 
              src={process.env.PUBLIC_URL + "/Authors/elizabeth-roberts.jpg"} 
              alt="Elizabeth Roberts" 
              className="author-image medium"
              style={{ top: '0%', right: '5%' }}
            />
            <img 
              src={process.env.PUBLIC_URL + "/Authors/dan-arthur-busby.jpg"} 
              alt="Dan Arthur Busby" 
              className="author-image small"
              style={{ top: '25%', right: '15%' }}
            />
          </div>
        </div>
      </section>

      {/* Updated Testimonials Section */}
      <section className="testimonials">
        <div className="testimonials-container">
          {testimonials.map((testimonial, index) => (
            <div key={index} className={`testimonial-card ${index % 2 === 0 ? 'red-bg' : 'yellow-bg'}`}>
              <div className="testimonial-content">
                <p className="testimonial-quote">{testimonial.quote}</p>
                <p className="testimonial-author">-{testimonial.author}, author of <em>{testimonial.book}</em></p>
                <button 
                  className="read-story-btn" 
                  onClick={() => handleAuthorStoryClick(testimonial)}
                >
                  {testimonial.buttonText}
                </button>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Publishing Section - Updated to "Who is talking about" section */}
      <section className="publishing">
        <h2>Who is talking about Executive Press authors?</h2>
        <p>The world is taking notice! Executive Press authors have been featured on:</p>
        <div className="brand-logos">
          <img src="https://www.authorhouse.com/-/media/asl/images/authorhouseus/site-art/logos---media-mentions/twsj.ashx" alt="Wall Street Journal" />
          <img src="https://www.authorhouse.com/-/media/asl/images/authorhouseus/site-art/logos---media-mentions/huffpost.ashx" alt="HuffPost" />
          <img src="https://www.authorhouse.com/-/media/asl/images/authorhouseus/site-art/logos---media-mentions/cbs.ashx" alt="CBS" />
          <img src="https://www.authorhouse.com/-/media/asl/images/authorhouseus/site-art/logos---media-mentions/readersdigest.ashx" alt="Reader's Digest" />
        </div>
      </section>

      {/* About Section */}
      <section className="about">
        <h2>About Us</h2>
        <p>Executive-Press guides aspiring authors through the path to success by providing high-quality, professional services and support. We've helped thousands of authors achieve their goals.</p>
        <button className="meet-authors-btn" onClick={handleReadOurStory}>Read Our Story</button>
      </section>

      {/* Consultation Form */}
      <section className="consultation" id="free-guide-section">
        <h2>Get your Free Guide!</h2>
        <form className="consultation-form" onSubmit={handleGuideSubmit}>
          <div className="form-row">
            <div className="form-group">
              <label>Full Name <span className="required">*</span></label>
              <input 
                type="text" 
                name="firstName"
                value={guideFormData.firstName}
                onChange={handleGuideInputChange}
                required 
              />
            </div>
            <div className="form-group">
              <label>Email <span className="required">*</span></label>
              <input 
                type="email" 
                name="email"
                value={guideFormData.email}
                onChange={handleGuideInputChange}
                required 
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Phone Number <span className="required">*</span></label>
              <input 
                type="tel" 
                name="phone"
                value={guideFormData.phone}
                onChange={handleGuideInputChange}
                required 
              />
            </div>
            <div className="form-group">
              <label>Service Inquiry <span className="required">*</span></label>
              <select 
                name="service"
                value={guideFormData.service}
                onChange={handleGuideInputChange}
                required
              >
                <option value="">Select a service</option>
                <option value="publishing">Publishing Services</option>
                <option value="marketing">Marketing Services</option>
              </select>
            </div>
          </div>
          <div className="form-group full-width">
            <label>Message</label>
            <textarea 
              name="message"
              value={guideFormData.message}
              onChange={handleGuideInputChange}
              placeholder="Message (Optional)" 
              rows="5"
            ></textarea>
          </div>
          <div className="form-group full-width">
            <div className="recaptcha">
              {/* Add your reCAPTCHA component here */}
            </div>
          </div>
          <button type="submit" className="submit-button">Submit</button>
        </form>

        {/* Loading Spinner */}
        {isLoading && (
          <div className="loading-overlay">
            <div className="loading-spinner">
              <div className="spinner"></div>
              <p className="loading-text">Sending your request...</p>
            </div>
          </div>
        )}

        {showSuccessDialog && (
          <div className="success-dialog">
            <div className="success-content">
              <h2>Success!</h2>
              <p>Your guide has been sent to your email.</p>
              <button onClick={handleCloseSuccessDialog}>Close</button>
            </div>
          </div>
        )}
      </section>

      {/* Success Dialog */}
      <div className={`dialog-overlay ${showSuccessDialog ? 'show' : ''}`}>
        <div className={`success-dialog ${showSuccessDialog ? 'show' : ''}`}>
          <div className="checkmark">
            <FontAwesomeIcon icon={faCheck} />
          </div>
          <h3>Thank You!</h3>
          <p>Your guide will be sent to your email shortly.</p>
          <button onClick={handleCloseSuccessDialog} className="close-dialog-button">Close</button>
        </div>
      </div>

      {showAuthorStory && (
        <div className="author-story-overlay" onClick={handleCloseAuthorStory}>
          <div className="author-story-dialog" onClick={e => e.stopPropagation()}>
            <button className="close-story-btn" onClick={handleCloseAuthorStory}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <div className="author-image">
              <img src={selectedAuthor.image} alt={selectedAuthor.author} />
            </div>
            <div className="author-details">
              <h2 style={{ color: 'white' }}>{selectedAuthor.author}</h2>
              <p className="book-title" style={{ color: 'white' }}>
                Author of <em style={{ color: 'white' }}>{selectedAuthor.book}</em>
              </p>
              <div className="rating">
                {[...Array(5)].map((_, i) => (
                  <FontAwesomeIcon key={i} icon={faStar} className="star" />
                ))}
              </div>
              <p className="author-feedback" style={{ color: 'white' }}>
                {selectedAuthor.quote}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
