import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { trackButtonClick } from '../utils/analytics';
import '../styles/VideoHeader.css';

const VideoHeader = () => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const handleContactClick = () => {
    trackButtonClick('Contact Us - Video Header');
  };

  useEffect(() => {
    // Load Vimeo Player API script
    const script = document.createElement('script');
    script.src = "https://player.vimeo.com/api/player.js";
    script.async = true;
    script.onload = () => setIsVideoLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="video-header">
      {!isVideoLoaded && (
        <div className="video-placeholder">
          {/* Loading placeholder */}
        </div>
      )}
      <div className="vimeo-wrapper">
        <iframe 
          src="https://player.vimeo.com/video/1047188665?h=0573da7a7c&badge=0&autopause=0&player_id=0&app_id=58479&background=1&autoplay=1&loop=1&muted=1"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          className={isVideoLoaded ? 'loaded' : ''}
          title="Executive Press Header Video"
        ></iframe>
      </div>
      <div className="video-overlay">
        {/* Your existing overlay content */}
      </div>
    </div>
  );
};

export default VideoHeader; 